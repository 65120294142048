@import"normalize";

$darkblue: #004262;
$lightblue: #01aee9;
$orange: #f7ab50;
$lightgray: #ebeff1;
$lightgray50: #ccc;
$white: #fff;

$maquetteWidth: 4331px;
$maquetteHeight: 2914px;


$headerHeight: 10%;
$headerBorderBottomHeight: 20px;

$sibebarWidth: 400px;
$sideBarMarginRight: 77px;

.text-size{
  font-size: 16px
}

body {
  background-color: $lightgray;
  min-height: 97%;
  color: $darkblue;
  font-size: .9rem;
}

a {
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
}

#main-navbar {
  background-color: $darkblue;
  height: $headerHeight;
  max-height: $headerHeight;
  overflow: hidden;
  border-bottom: 8px solid $orange;
}

.inputs{
  width: calc(100% - 35px);
}
.training{
  width: 35px;
  padding-right: 3px;
}

a.navbar-brand {
  color: $white;
  font-size: 1.5rem;
}
a.navbar-brand:hover {
  color: $lightgray;
}

#navbar-subtile {
  font-size: 1.20rem;
  margin-bottom: 0;
  border-bottom: 1px solid $white;
}

.profile-pic, .navbar-item {
  cursor: pointer;
}

.profile-pic > img:first-child{
  max-height: 30px;
  margin-right: 5px;
}

.profile-pic > img:last-child{
  max-height: 10px;
}

.navbar-item {
  color: $white;
  font-size: 1.25rem;
  font-weight: 300;
  display: flex;
}

.navbar-item:hover > a {
  color: $lightgray;
}

.navbar-item  > img {
  height: 20px;
  margin-right: 8px;
}

#sidebar-wrapper > ul {
  width: 130px;
  position: relative;
  left: 0;
}

.big-text {
  color: $darkblue;
  font-size: .9rem;
  font-weight: 300;
}

.sidebar-item > span {
  @extend .big-text;
}
.sidebar-item:hover > span {
  color: $lightblue;
}

.sidebar-item > img {
  max-height: 50px;
}

.sidebar-item.active {
  background-color: $lightgray;
}

.sidebar-item.active > span {
  color: $lightblue;
}

.corner {
  border-top-right-radius: 15px;
}

.sidebar-wrapper > ul {
  margin-top: 2rem;
}

.sidebar-item:not(.active) {
  cursor: pointer;
  background: $white;
}

.content-row {
  border-radius: 15px;

}

.light-shadow {
  box-shadow: 0 2px 0 0 rgba(0,0,0,.016);
}
.small-blue-text {
  color: $lightblue;
}

.fees-reel {
  border-bottom: 1px solid #004262;
  border-top: 1px solid #004262;
}

#dashboard-content-wrapper {
  flex: 1;
}

.declarant-wrapper{
  border: 1px solid $darkblue;
  // min-height: 200px;
  border-radius: 5px;
  height: 100%;
}

.declarant-wrapper1{
  border: 1px solid $darkblue;
  border-radius: 5px;
}

.col-md-12 > .declarant-wrapper1{
  min-height: 200px;
}
.col-md-12 > .declarant-wrapper{
  min-height: auto;
}


.declarant-wrapper2{
  border-top: 1px solid $darkblue;
  min-height: 100px;
}

.declarant-wrapper2{
  border-top: 1px solid $darkblue;
  min-height: 100px;
}
.declarant-wrapper3{
  border: 1px solid $darkblue;
  border-radius: 5px;
}

.declarant-wrapper h1, .declarant-wrapper2 h1{
  text-align: center;
  margin-top: -10px;
  height: 20px;
  line-height: 20px;
  font-size: 15px;
}

.declarant-wrapper h1 span, .declarant-wrapper2 h1 span{
  background-color: $white;
}

.declarant-wrapper1 h1, .declarant-wrapper2 h1{
  text-align: center;
  margin-top: -10px;
  height: 20px;
  line-height: 20px;
  font-size: 15px;
}

.declarant-wrapper1 h1 span, .title-section span, .declarant-wrapper2 h1 span{
  background-color: $white;
}
/* ================================================*/ 

a {
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
}

#main-navbar {
  background-color: $darkblue;
  height: $headerHeight;
  max-height: $headerHeight;
  overflow: hidden;
  border-bottom: 8px solid $orange;
  min-height: 80px;
}

a.navbar-brand {
  color: $white;
  font-size: 1.5rem;
}
a.navbar-brand:hover {
  color: $lightgray;
}

#navbar-subtile {
  font-size: 1.20rem;
  margin-bottom: 0;
  border-bottom: 1px solid $white;
}

.profile-pic, .navbar-item {
  cursor: pointer;
}

.profile-pic > img:first-child{
  max-height: 30px;
  margin-right: 5px;
}

.profile-pic > img:last-child{
  max-height: 10px;
}

.navbar-item {
  color: $white;
  font-size: 1.25rem;
  font-weight: 300;
  display: flex;
}

.navbar-item:hover > a {
  color: $lightgray;
}

.navbar-item  > img {
  height: 20px;
  margin-right: 8px;
}

#sidebar-wrapper > ul {
  width: 130px;
  position: relative;
  left: 0;
}

.big-text {
  color: $darkblue;
  font-size: .9rem;
  font-weight: 300;
}

.synthese-img > span {
  color: $lightgray;
  font-size: .9rem;
  font-weight: 300;
}

.sidebar-item > span{
  @extend .big-text;
}
.sidebar-item:hover > span {
  color: $lightblue;
}

.sidebar-item > img {
  max-height: 50px;
  max-width: 50px;
}

.synthese-img > img {
  max-height: 60px;
  max-width: 60px;
}

.sidebar-item.active {
  background-color: $lightgray;
}

.sidebar-item.active > span {
  color: $lightblue;
}

.corner {
  border-top-right-radius: 15px;
}

.sidebar-wrapper > ul {
  margin-top: 2rem;
}

.sidebar-item:not(.active) {
  cursor: pointer;
  background: $white;
}

.content-row {
  border-radius: 15px;

}

.light-shadow {
  box-shadow: 0 2px 0 0 rgba(0,0,0,.016);
}
.small-blue-text {
  color: $lightblue;
}

.fees-reel {
  border-bottom: 1px solid #004262;
  border-top: 1px solid #004262;
}

#dashboard-content-wrapper {
  flex: 1;
}

.declarant-wrapper{
  border: 1px solid $darkblue;
  // min-height: 200px;
  border-radius: 5px;
  height: 100%;
}

.declarant-wrapper2{
  border-top: 1px solid $darkblue;
  min-height: 100px;
}
.declarant-wrapper3{
  border: 1px solid $darkblue;
  border-radius: 5px;
}

.declarant-wrapper h1, .title-section, .declarant-wrapper2 h1{
  text-align: center;
  margin-top: -10px;
  height: 20px;
  line-height: 20px;
  font-size: 15px;
}

.declarant-wrapper h1 span, .title-section span, .declarant-wrapper2 h1 span{
  background-color: $white;
}

.form-control2 {
  display: block;
  width: 100%;
  text-align: center;
  /*font-size: 1rem;*/
  font-size: 80%;
  font-weight: 400;
  line-height: 1.5;
  /*color: #495057;*/
 /* color: rgb(157, 150, 142);*/
 color: $darkblue;
  background-color: transparent;
  background-clip: padding-box;
  border: none;
 /* border: 1px solid #ced4da;*/
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.form-control-page{
  display: block;
  width: 50px;
  text-align: center;
  /*font-size: 1rem;*/
  font-size: 80%;
  font-weight: 400;
  line-height: 1.5;
  /*color: #495057;*/
 /* color: rgb(157, 150, 142);*/
  color: $darkblue;
  background-color: transparent;
  background-clip: padding-box;
  border: 1px solid $lightgray50;
 /* border: 1px solid #ced4da;*/
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}



.form-control3 {
  display: block;
  width: 100%;
  text-align: right;
  /*font-size: 1rem;*/
  font-size: 80%;
  font-weight: 400;
  line-height: 1.5;
  /*color: #495057;*/
 /* color: rgb(157, 150, 142);*/
  color: $darkblue;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
 /* border: 1px solid #ced4da;*/
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.form-control2:focus,.form-control-page:focus {
  outline: none;
}
.range-wrapper {
  flex: 1;
}

.flex-1 {
  flex: 1;
}

.border-light-gray {
  border: 1px solid $lightgray50;
  min-height: 30px;
  max-height: 30px;
  overflow: hidden;
}
.border-white {
  border: 1px solid #fff;
  min-height: 30px;
  max-height: 30px;
  overflow: hidden;
}


.text-dark-blue {
  color: $darkblue;
}

.bg-orange {
  background-color: $orange;
}

.bg-darkblue {
  background-color: $darkblue;
}

.custom-control.custom-checkbox * {
    cursor: pointer;
}

#overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.1);
  z-index: 2;
  cursor: pointer;
}

#overlay > div {
  min-height: 100vh;
  display: flex;
  align-content: center;
  z-index: 999;
}
.swal-text {
  text-align: center;
}

.custom-modal-header {
  background: $darkblue;
    color: $white;
    border-bottom: 5px solid $orange;
}

.custom-modal-header > button {
  color: $white;
}

.custom-modal-header > button:focus, .custom-modal-header > button > span:focus {
  outline: none;
}

.custom-modal-header > button.close:hover {
  color: $orange;
}

html, body, #root, #main-wrapper{
  width:100%;
  height:97.4%;
  max-width:100%;
  max-height:100%;
  //overflow: hidden;
}

#content-wrapper {
  width:100%;
  height:90%;
  max-width:100%;
  max-height:90%;
  //overflow: hidden;
}

#dynamic-content {
  width:100%;
  // height:55%;
  // min-height: 420px;
  max-width:100%;
 // overflow: hidden;
}

.h-100 {
  height: 100% !important;
}

.h-95 {
  height: 95% !important;
}

.adh::before {
  top: .1rem;
}

.big-text-blue {
  color: $lightblue;
  font-size: 1rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.textarea {
  resize: none;
}

.d-flex{
  display: -moz-flexbox !important;
  display: -ms-flexbox !important;
}

.icon-color {
  color: $darkblue;
}
.react-date-picker__wrapper{
  border-color: #ccc !important;
}
.react-date-picker {
  display: -ms-inline-flexbox;
  display: block !important;
  position: relative;
  padding-left: 0px !important;
  padding-right: 0px !important;
  height: 31px;
}
.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.inputfile + label {
  font-size: 1.05em;
  font-weight: 600;
  color: white;
  background-color: $orange;
  display: inline-block;
  padding: 5px;
  border-radius: 5px
}

.inputfile:focus + label,
.inputfile + label:hover {
  background-color: $darkblue;
}

.inputfile + label {
	cursor: pointer; /* "hand" cursor */
}

.inputfile + label * {
	pointer-events: none;
}
