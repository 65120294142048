$darkblue: #003F5E;
$lightblue: #01aee9;
$orange: #f7ab50;
$lightgray: #ebeff1;
$lightgray50: #ccc;
$white: #fff;
$rouge: #FF0202;
$img: url('../../_assets/images/easydefisc.jpg');

.bg-darkblue{
    background-color: $darkblue;
}
.main-auth{
    height: 100vh;
    background-image: $img;
    background-repeat: no-repeat;
    background-size: cover;
}
.opacity-content{   
    background-color: rgba($color:$darkblue, $alpha: 0.50);
    width: 100%;
    height: 100%;
}
.btn-orange{
    background-color: $orange;
    color:$white
}
.btn-round{
    border-radius: 50px;
    padding-right: 30px;
    padding-left: 30px;
}
.auth-content{
    height: calc(100% - calc(10% + 8px));
    margin-top: 20px;
}

.form-round{
    border-radius: 50px;
}
.title-form{
    font-size: 18px;
    font-style: oblique;
}
.profile-group{
    position: relative;
}
.dropdown-user{
    position: absolute;
    background-color: $white;
    border-radius: 8px;
    z-index: 999;
    right: 8px;
    width: 170px;
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.75);
    .text-content{
        line-height: 1;
    }
    .dropdow-item{
        cursor: pointer;  
        padding: 8px 16px; 
    }
    .dropdow-item:hover{
        background-color: $lightgray;   
    }
}
.form>span {
    float: right;
    margin-right: 10px;
    font-size: 12px ;
    color: $rouge;
    font-weight: 600;
}
.field-input{
    min-height: 36px !important;
}
.form-control3 {
    display: block;
    width: 100%;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.2;
    width: 180px;
    height: 50px;
    color: $darkblue;
    background-color: #fff;
    background-clip: padding-box;
    border: none;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }
  .form-control3:focus {
    outline: none;
  }
  .table-box > table >thead>tr{
      background-color: $darkblue;
      color: $white;
      border-bottom: 6px solid $orange;
      th{
          vertical-align: top;
      }
  }
  .text-bleu-ciel{
      color: $lightblue;
  }
  .table thead th{
    border: none !important;
    padding:8px
  }

  .table-box > table > thead> tr >th:first-child{
    border-radius: 20px 0px 0px 0px;
  }
  .table-box > table > thead> tr >th:last-child{
    border-radius: 0px 20px 0px 0px;
  }


  .th-div th{
    font-size: 10px;
    font-weight: 300;
  }
  .text-orange{
    color:$orange
  }
  .table td{
    text-align: center;
    border: none;
    vertical-align: middle;
    font-weight: 700;
    padding: 0.5rem
}
.picto-icon{
    height: 35px;
}

tbody {
    display:block;
    //min-height:40vh;
    max-height: 430px;
    overflow:auto;
}

tbody::-webkit-scrollbar {
    width: 0.5em;
}
   
tbody::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

tbody::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

thead, tbody tr {
    display:table;
    width:100%;
    table-layout:fixed;/* even columns width , fix width of table too*/
}
thead {
    width: 100% /* scrollbar is average 1em/16px width, remove it from thead width */
}
#sidebar-wrapper{
    height: 450px !important;
}

#tooltip-top > .tooltip-inner {
    background-color: $white;
    color: $darkblue;
    font-size: 10px;
    font-style: italic;;
    border: 1px solid $darkblue;
  }
  
  #tooltip-top > .tooltip-arrow {
    border-top: 5px solid $darkblue;
  }
  #dynamic-content2 {
    width:100%;
  }
  .modal-body {
    padding: 1rem 0rem !important;
  }
.custom-control-check{
    position: absolute;
    left: 0;
    width: 1rem;
    height: 1.25rem;
    opacity: 0.7;
}
.para-margin{
    margin-bottom: 0px;
}
.select-center{
    text-align-last: center;
}
.card-header{
    padding: 0px !important;
    background-color: $lightgray !important;
}
.card-header-tabs {
    margin-right: -.625rem;
    margin-bottom: 0px !important;
    margin-left: -.625rem;
    border-bottom: 1px solid #dee2e6 !important;
}

.btn-link {
    font-size: 14.4px !important;
    color: $darkblue !important;
    text-decoration: none;
}
.btn:focus {
    outline: 0;
    box-shadow: none;
}
.btn-link:hover {
    font-size: 14.4px !important;
    color: $darkblue !important;
    text-decoration: none;
}
.mgl{
    margin-left: 20px;
}
.mb{
    margin-bottom: 0px !important;
}

.backrounGris{
    background-color: rgba(0, 123, 255, 0.3);
}
@media only screen and (max-width: 992px) {
    .mb{
        margin-bottom: 1rem !important;
    }
}

@media only screen and (max-height: 700px) {
    tbody {
        max-height: 320px;
    }
}
.modal-dialog {
    min-width: 550px;
    margin: 1.75rem auto;
}
.wh{
    width: 100%;
}
@media (max-width: 576px){
    .wh{
        width: 50%;
    }
    .modal-dialog {
        min-width: auto !important;
        max-width: 550px;
        margin: 1.75rem auto;
    }
    #sidebar-wrapper{
        width: 100% !important;
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;
        margin-bottom: 1rem !important;
    }
    #sidebar-wrapper ul{
        width: 100% !important;
    }
    .navbar-nav{
        flex-direction: row !important;
    }
    #sidebar-wrapper span {
        font-size: 12.5px;
    }
    .mgl{
        margin-left: 0px;
    }
    .picto-icon{
        height: 30px;
    }
    .form-control3 {
        text-align: center;
    }
    .sidebar-item.active {
        padding: 0px 5px 0px 5px;
    }
    .sidebar-item:not(.active) {
        cursor: pointer;
        padding-right: .5rem !important;
        padding-left: .5rem !important;
    }
    .label-card{
        display: none !important;
    }
    .btn {
        padding: .15rem .55rem;
    }
    #sidebar-wrapper{
        height: auto !important;
    }
    .btn-footer-box{
        span{
            display: none;
        }
        .rounded{
            border-radius: 50% !important;
        }
    }
    .icon-size{
        font-size:50px !important;
    }
    .img-size{
        width: 25px !important;
        max-width: 25px !important;
        height: 25px;
    }
}
.modal-90w{
    min-width: 95% !important;
}

.small-radio{
    font-size: 11.52px;
    padding-top: 5px;
}
.icon-size{
    font-size:20px
}
