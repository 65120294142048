@media
	  screen 
    and (max-width: 760px), (min-device-width: 768px) 
    and (max-device-width: 1024px)  {

		/* Force table to not be like tables anymore */
		table, thead, tbody, th, td, tr {
			display: block;
		}

		/* Hide table headers (but not display: none;, for accessibility) */
		thead tr {
			position: absolute;
			top: -9999px;
			left: -9999px;
		}

    tr {
	  margin: 0 0 1rem 0;
	//   border-bottom: 0.25px solid #004262;
    }
      
    tr:nth-child(odd) {
      background: #ccc;
    }
    
		td {
			/* Behave  like a "row" */
			// border: none;
			// border-bottom: 1px solid #004262;
			position: relative;
            padding-left: 50%;
            width: auto !important;
        }
        
        .table td {
            text-align: left;
            // border-bottom: 1px solid #004262;
            vertical-align: middle;
            font-weight: 700;
            padding-left: 60%;
        }
		td:before {
			/* Now like a table header */
			position: absolute;
			/* Top/left values mimic padding */
			top: 0;
			left: 6px;
			width: 45%;
			padding-right: 10px;
            white-space: nowrap;
            // border-right: 1px solid #004262;
        }
        td:after {
			/* Now like a table header */
			position: absolute;
			/* Top/left values mimic padding */
			top: 0;
			left: 6px;
			width: 50%;
			padding-right: 10px;
			white-space: nowrap;
		}

		/*
		Label the data
    You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
		*/
 		.accueil td:nth-of-type(1):before { content: "CLIENT"; padding: 8px; text-align: left; width: 50%;}
		.accueil td:nth-of-type(2):before { content: "DATE SIMULATION"; padding: 8px; text-align: left; width: 50%;}
		.accueil td:nth-of-type(3):before { content: "IMPOTS"; padding: 8px; text-align: left; width: 50%;}
		.accueil td:nth-of-type(4):before { content: "NB DE PARTS FISCALES"; padding: 8px; text-align: left; width: 50%; }
		.accueil td:nth-of-type(5):before { content: "LOI"; padding: 8px; text-align: left; width: 50%; }
		.accueil td:nth-of-type(6):before { content: "LOT"; padding: 8px; text-align: left; width: 50%; }
		.accueil td:nth-of-type(7):before { content: "PRIX"; padding: 8px; text-align: left; width: 50%; }
		.accueil td:nth-of-type(8):before { content: "CREDIT"; padding: 8px; text-align: left; width: 50%; }
		.accueil td:nth-of-type(9):before { content: "TAUX"; padding: 8px; text-align: left; width: 50%; }
		.accueil td:nth-of-type(10):before { content: "MODIFICATION"; padding: 8px; text-align: left; width: 50%; }

		.immobillier td:nth-of-type(1):before { content: "Nom du produit de financement"; padding: 8px; text-align: left; width: 50%;}
		.immobillier td:nth-of-type(2):before { content: "Type emprunt"; padding: 8px; text-align: left; width: 50%;}
		.immobillier td:nth-of-type(3):before { content: "Durée emprunt"; padding: 8px; text-align: left; width: 50%;}
		.immobillier td:nth-of-type(4):before { content: "Taux d'emprunt"; padding: 8px; text-align: left; width: 50%; }
		.immobillier td:nth-of-type(5):before { content: "Ass. décés inv. pour 10000€"; padding: 8px; text-align: left; width: 50%; }
		.immobillier td:nth-of-type(6):before { content: "Différé rembours. du capital emprunté"; padding: 8px; text-align: left; width: 50%; }
		.immobillier td:nth-of-type(7):before { content: "Nbre mois du différé du capital"; padding: 8px; text-align: left; width: 50%; }
		.immobillier td:nth-of-type(8):before { content: "Différé rembours. des intérêts d'emprunt"; padding: 8px; text-align: left; width: 50%; }
		.immobillier td:nth-of-type(9):before { content: "Nbre mois du différé des intérêts"; padding: 8px; text-align: left; width: 50%; }
		.immobillier td:nth-of-type(10):before { content: "Taux capitalisation"; padding: 8px; text-align: left; width: 50%; }
		
		.finance td:nth-of-type(1):before { content: "Nom du produit Immobilier"; padding: 8px; text-align: left; width: 50%;}
		.finance td:nth-of-type(2):before { content: "Loi defiscalisation"; padding: 8px; text-align: left; width: 50%;}
		.finance td:nth-of-type(3):before { content: "Année de livraison"; padding: 8px; text-align: left; width: 50%;}
		.finance td:nth-of-type(4):before { content: "Surface fiscale"; padding: 8px; text-align: left; width: 50%; }
		.finance td:nth-of-type(5):before { content: "zone"; padding: 8px; text-align: left; width: 50%; }
		.finance td:nth-of-type(6):before { content: "Montant total produit"; padding: 8px; text-align: left; width: 50%; }
		.finance td:nth-of-type(7):before { content: "Loyer mensuel"; padding: 8px; text-align: left; width: 50%; }
		.finance td:nth-of-type(8):before { content: "Frais de gestion"; padding: 8px; text-align: left; width: 50%; }
		.finance td:nth-of-type(9):before { content: "Frais de garantie"; padding: 8px; text-align: left; width: 50%; }
		.finance td:nth-of-type(10):before { content: "Charge de copropriété"; padding: 8px; text-align: left; width: 50%; }
		.finance td:nth-of-type(11):before { content: "Montant taxe foncière"; padding: 8px; text-align: left; width: 50%; }
		.finance td:nth-of-type(12):before { content: "Exo. taxe foncière"; padding: 8px; text-align: left; width: 50%; }
		.finance td:nth-of-type(13):before { content: "Exo. taxe foncière pendant"; padding: 8px; text-align: left; width: 50%; }
		.finance td:nth-of-type(14):before { content: "Taux de charge"; padding: 8px; text-align: left; width: 50%; }

		.client td:nth-of-type(1):before { content: "Date de simulation"; padding: 8px; text-align: left; width: 50%;}
		.client td:nth-of-type(2):before { content: "Non client"; padding: 8px; text-align: left; width: 50%;}
		.client td:nth-of-type(3):before { content: "Revenu fiscal de référence"; padding: 8px; text-align: left; width: 50%;}
		.client td:nth-of-type(4):before { content: "Nombre de parts fiscales"; padding: 8px; text-align: left; width: 50%;}
		.client td:nth-of-type(5):before { content: "Résultat foncier"; padding: 8px; text-align: left; width: 50%; }
		.client td:nth-of-type(6):before { content: "Tranche Marginale d'Imposition"; padding: 8px; text-align: left; width: 50%; }
		.client td:nth-of-type(7):before { content: "Impôt de référence"; padding: 8px; text-align: left; width: 50%; }
		.client td:nth-of-type(8):before { content: "Evolution de votre situation actuelle"; padding: 8px; text-align: left; width: 50%; }
		
		.resultatFiscal td:nth-of-type(1):before { content: "Année"; padding: 8px; text-align: left; width: 50%;}
		.resultatFiscal td:nth-of-type(2):before { content: "Impôt payé"; padding: 8px; text-align: left; width: 50%;}
		.resultatFiscal td:nth-of-type(3):before { content: "TMI"; padding: 8px; text-align: left; width: 50%;}
		.resultatFiscal td:nth-of-type(4):before { content: "Taux d'endettement"; padding: 8px; text-align: left; width: 50%; }
		.resultatFiscal td:nth-of-type(5):before { content: "ISF"; padding: 8px; text-align: left; width: 50%; }
		
	}